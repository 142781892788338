import type { LangType } from '.';

const es: LangType = {
  translation: {
    name_ordinal_one: '{{count}}º Gran Premio del Minho',
    name_ordinal_two: '{{count}}º Gran Premio del Minho',
    name_ordinal_few: '{{count}}º Gran Premio del Minho',
    name_ordinal_other: '{{count}}º Gran Premio del Minho',
    name: 'Grande Premio del Minho',
    prizes: {
      prizes: 'Premios',
      yellow: 'General Individual',
      green: 'General Puntos',
      red: 'General Metas Volantes',
      dots: 'General Montaña',
      orange: 'Mejor Júnior 1er Año',
      white: 'Mejor Atleta ACM',
    },
    day_one: 'día',
    day_other: 'días',
    hour_one: 'hora',
    hour_other: 'horas',
    minute_one: 'minuto',
    minute_other: 'minutos',
    second_one: 'segundo',
    second_other: 'segundos',
    dates: '{{begin}} a {{end}} de julio',
    powered_by: 'con apoyo de',
    teams: 'Equipos',
    organization: 'Organización',
    main_sponsors: 'Patrocinadores principales',
    sponsors: 'Patrocinadores',
    stage: {
      stage_one: 'Etapa',
      stage_other: 'Etapas',
      see: 'Ver etapa',
      map: 'Mapa de la Etapa',
      profile: 'Perfil de la Etapa',
      final: 'Últimos 3km',
      next: 'Próxima etapa',
    },
    date: '{{day}} julio',
    results: 'Clasificaciones',
    news: 'Noticias',
    book: 'Libro de prueba',
    rules: 'Reglamento',
  },
};

export default es;
