import React from 'react';

import { VStack, Flex } from '@chakra-ui/react';

import Header from './Header';

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <VStack w='100%' h='100dvh' bg='white' position='relative' spacing='0' overflowY='auto'>
      <Header />
      <Flex w='100%' justify='center' overflowY='auto'>
        {children}
      </Flex>
    </VStack>
  );
}
