import React from 'react';

import { Container } from '@chakra-ui/react';

import Prizes from '../../components/Prizes';

export default function PrizesPage() {
  return (
    <Container
      w='100vw'
      maxW='1600px'
      h='100dvh'
      pt={{ base: '120px', lg: '200px' }}
      px={{ base: '16', lg: '24' }}
    >
      <Prizes />
    </Container>
  );
}
