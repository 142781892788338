import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Heading, VStack, Image, Text, Stack } from '@chakra-ui/react';

export default function Stage({
  day,
  location,
  order,
}: {
  day: string;
  location: string;
  order: number;
}) {
  const { t } = useTranslation();
  const currentYear = '2024';

  return (
    <Container maxW='1600px' mt='120px' p='8' position='relative'>
      <VStack spacing='8'>
        <Heading fontSize={{ base: '2xl', md: '4xl' }}>
          {t('date', { day })} - {location}
        </Heading>
        <Stack direction={['column', 'row']} spacing='8' alignItems='start'>
          <VStack maxW={{ base: '100%', lg: '50%' }} spacing='2'>
            <Text w='100%' fontWeight='600'>
              {t('stage.map')}
            </Text>
            <Image src={require(`../../assets/stages/${currentYear}/${order}/map`)} />
          </VStack>
          <VStack maxW={{ base: '100%', lg: '50%' }} spacing='8' alignItems='start'>
            <VStack spacing='2'>
              <Text w='100%' fontWeight='600'>
                {t('stage.profile')}
              </Text>
              <Image src={require(`../../assets/stages/${currentYear}/${order}/profile`)} />
            </VStack>
            <VStack spacing='2'>
              <Text w='100%' fontWeight='600'>
                {t('stage.final')}
              </Text>
              <Image src={require(`../../assets/stages/${currentYear}/${order}/final`)} />
            </VStack>
          </VStack>
        </Stack>
      </VStack>
    </Container>
  );
}
