import { extendTheme } from '@chakra-ui/react';

const theme = {
  fonts: {
    heading: 'Monument Extended, sans-serif',
    body: 'Surt, sans-serif',
  },
  colors: {
    brand: {
      50: '#99c1ab',
      100: '#67a282',
      200: '#4d936d',
      300: '#348358',
      400: '#1a7443',
      500: '#01642e',
      600: '#015a29',
      700: '#014620',
      800: '#013217',
      900: '#001e0e',
    },
    gray: {
      50: '#ffffff',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#c0c0c0',
      400: '#636366',
      500: '#48484a',
      600: '#3a3a3c',
      700: '#202425',
      800: '#151718',
      900: '#000000',
    },
  },
  config: {
    useSystemColorMode: false,
    initialColorMode: 'light',
  },
  styles: {
    global: {
      'html, body': {
        bg: 'gray.50',
        color: 'gray.900',
      },
    },
  },
  components: {
    Link: {
      baseStyle: {
        _hover: { textDecoration: 'none' },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          py: '1',
          borderRadius: 'none',
          bg: 'white',
          boxShadow: '0 0 2px 2px rgba(0, 0, 0, 0.1)',
        },
        header: {
          fontSize: 'md',
          borderBottom: '1px solid',
          borderColor: 'gray.700',
          py: '3',
        },
        body: {
          py: '5',
        },
        footer: {
          py: '3',
          borderTop: '1px solid',
          borderColor: 'gray.700',
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 'lg',
        textTransform: 'uppercase',
        fontWeight: 'regular',
        _hover: {
          textDecoration: 'none',
        },
      },
      variants: {
        primary: {
          bg: 'brand.500',
          color: 'white',
          fontFamily: 'Surt, sans-serif',
          fontWeight: '600',
          fontSize: 'sm',
          px: '8',
          py: '4',
          _hover: {
            boxShadow: 'inset 0 -4em 0 0 white',
            color: 'brand.500',
            transition: 'all .7s cubic-bezier(.19,1,.22,1)',
          },
        },
      },
    },
  },
};

export default extendTheme(theme);
