import { useTranslation } from 'react-i18next';
import { FiChevronDown } from 'react-icons/fi';

import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

const lngs = {
  en: { nativeName: 'English' },
  es: { nativeName: 'Español' },
  pt: { nativeName: 'Português' },
};

function LanguageSelector() {
  const { i18n } = useTranslation();

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            as={Button}
            rightIcon={<FiChevronDown />}
            fontSize='x-small'
            bgColor='transparent'
            border='1px solid black'
            _hover={{
              bgColor: 'rgba(0, 0, 0, 0.2)',
            }}
            _active={{
              bgColor: 'rgba(0, 0, 0, 0.2)',
            }}
          >
            {i18n.resolvedLanguage.toUpperCase()}
          </MenuButton>
          <MenuList>
            {Object.keys(lngs).map(lng => (
              <MenuItem
                key={lng}
                style={{
                  fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal',
                }}
                onClick={() => i18n.changeLanguage(lng)}
              >
                {lngs[lng].nativeName}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
}

export default LanguageSelector;
