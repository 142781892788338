import React from 'react';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
// import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  // Button,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  Link,
} from '@chakra-ui/react';

// import stage1 from '../../assets/stages/stage-1/profile.jpg';
// import stage2 from '../../assets/stages/stage-2/profile.jpg';
// import stage3 from '../../assets/stages/stage-3/profile.jpg';

import acm from '../../assets/organizers/acm.png';
import fpc from '../../assets/organizers/fpc.png';

import coura from '../../assets/apoios/paredes_coura.svg';

import Prizes from '../../components/Prizes';
import Teams from '../../components/Teams';
import Countdown from '../../components/Countdown';

export default function Home() {
  const { t } = useTranslation();

  const now = new Date();
  const etapa1 = new Date('2024-07-19T14:00:00');
  const etapa2 = new Date('2024-07-20T13:00:00');
  const etapa3 = new Date('2024-07-21T12:00:00');
  const endDate = now < etapa1 ? etapa1 : now < etapa2 ? etapa2 : now < etapa3 ? etapa3 : null;

  return (
    <Flex direction='column' w='100%'>
      <Image
        src={require('../../assets/bg.jpg')}
        position='absolute'
        w='100%'
        h='100%'
        left='0'
        top='0'
        zIndex='1'
        objectFit='cover'
        filter='brightness(70%)'
      />
      <VStack position='absolute' w='100%' h='100dvh' pt='100px' left='0' top='0' zIndex='2'>
        <Stack
          h='calc(100dvh - 100px)'
          w='100%'
          maxW='1600px'
          justify='center'
          spacing='12'
          zIndex='2'
          px={{ base: '6', md: '10', lg: '20' }}
        >
          <Stack w='100%' justify='center' spacing='20'>
            <Stack w='100%' justify='center' direction={['column', 'row']}>
              <Text
                w='100%'
                align='center'
                color='white'
                fontSize={{ base: '50', md: '80' }}
                fontFamily='NeoSans'
                textShadow='0px 8px 8px rgba(0, 0, 0, 0.5)'
              >
                {t('name', { count: 34, ordinal: true })}
              </Text>
            </Stack>
            {endDate ? (
              <VStack w='100%' justify='center' spacing={{ base: '0', md: '4', lg: '8' }}>
                {now > etapa1 && (
                  <Text
                    w='100%'
                    align='center'
                    color='white'
                    fontSize={{ base: '15', md: '30' }}
                    textShadow='0px 8px 8px rgba(0, 0, 0, 0.5)'
                  >
                    {t('stage.next')}
                  </Text>
                )}
                <Countdown endDate={endDate} />
              </VStack>
            ) : (
              <Text
                w='100%'
                align='center'
                color='white'
                fontSize={{ base: '30', md: '60' }}
                textShadow='0px 8px 8px rgba(0, 0, 0, 0.5)'
              >
                {t('dates', { begin: '19', end: '21' })}
              </Text>
            )}
          </Stack>
        </Stack>
      </VStack>
      <Flex w='100%' pt='100dvh' zIndex='2'>
        <Flex direction='column' w='100%' align='center' bg='white' borderTopRadius='32'>
          <Container w='100%' maxW='1600px' px={{ base: '4', md: '12', lg: '24' }}>
            {/* <Etapas w='100%' py='12' /> */}
            <VStack w='100%' spacing='8' py='12'>
              <VStack w='100%' spacing='0'>
                <Heading>{t('prizes.prizes')}</Heading>
                <HStack alignItems='center' justifyContent='center' spacing='1'>
                  <Text fontSize='14px'>{t('powered_by')}</Text>
                  <Box as={Link} href={'https://ulevel.es'} target='_blank' h='20px'>
                    <Image src={require('../../assets/partners/ulevel.png')} h='100%' />
                  </Box>
                </HStack>
              </VStack>
              <Prizes w='100%' />
            </VStack>
            <VStack w='100%' spacing='8' py='12'>
              <Heading>{t('teams')}</Heading>
              <Teams w='100%' />
            </VStack>
            <Parceiros w='100%' py='12' t={t} />
          </Container>
        </Flex>
      </Flex>
    </Flex>
  );
}

// function Etapas({
//   t,
//   ...props
// }: {
//   t: TFunction<'translation', undefined>;
//   [x: string]: any;
// }): React.JSX.Element {
//   return (
//     <VStack spacing='8' {...props}>
//       <Heading>{t('stage.stage', { count: 3 })}</Heading>
//       <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap='8'>
//         <VStack w='100%' spacing='4'>
//           <Image src={stage1} />
//           <Heading fontSize='sm'>Paredes de Coura</Heading>
//           <Button as={RouterLink} to='/etapas/1' variant='primary'>
//             {t('stage.see')}
//           </Button>
//         </VStack>
//         <VStack w='100%' spacing='4'>
//           <Image src={stage2} />
//           <Heading fontSize='sm'>Azúrem / Guimarães</Heading>
//           <Button as={RouterLink} to='/etapas/2' variant='primary'>
//             {t('stage.see')}
//           </Button>
//         </VStack>
//         <VStack w='100%' spacing='4'>
//           <Image src={stage3} />
//           <Heading fontSize='sm'>Oliveira Santa Maria / Famalicão</Heading>
//           <Button as={RouterLink} to='/etapas/3' variant='primary'>
//             {t('stage.see')}
//           </Button>
//         </VStack>
//       </SimpleGrid>
//     </VStack>
//   );
// }

function Parceiros({
  t,
  ...props
}: {
  t: TFunction<'translation', undefined>;
  [x: string]: any;
}): React.JSX.Element {
  const principais = [
    {
      link: 'https://www.jf-oliveirasantamaria.pt/',
      imagem: require('../../assets/apoios/santamaria.jpg'),
    },
    { link: 'https://www.jfazurem.pt/', imagem: require('../../assets/apoios/azurem.jpg') },
    { link: 'https://www.famalicao.pt/', imagem: require('../../assets/apoios/famalicao.png') },
    { link: 'https://www.cm-guimaraes.pt/', imagem: require('../../assets/apoios/guimaraes.png') },
    {
      link: 'https://www.paredesdecoura.pt/',
      imagem: coura,
    },
  ];

  const outros = [
    {
      link: 'https://www.facebook.com/arrecadacoes',
      imagem: require('../../assets/partners/arrecadacoes.png'),
    },
    { link: 'https://autoterror.com/', imagem: require('../../assets/partners/autoterror.png') },
    { link: 'https://poppagency.com/', imagem: require('../../assets/partners/popp.png') },
    { link: 'https://i9tex.pt/', imagem: require('../../assets/partners/i9tex.png') },
    { link: 'https://www.cision.pt/', imagem: require('../../assets/partners/cision.png') },
    { link: 'https://www.raizcarisma.pt/', imagem: require('../../assets/apoios/raizcarisma.jpg') },
    { link: null, imagem: require('../../assets/apoios/seg3.jpg') },
    { link: 'https://ulevel.es', imagem: require('../../assets/partners/ulevel.png') },
    {
      link: 'https://www.pavimogege.com/',
      imagem: require('../../assets/partners/pavimogege.png'),
    },
    {
      link: 'https://www.crispimabreu.pt/',
      imagem: require('../../assets/partners/crispimabreu.png'),
    },
    {
      link: 'https://www.viamaris.pt/',
      imagem: require('../../assets/partners/viamaris.png'),
    },
  ];

  return (
    <VStack align='start' spacing='8' {...props}>
      <VStack w={{ base: '70%', md: '30%' }} align='start' spacing='2'>
        <Text fontSize={{ base: 'lg', xl: 'xl' }} fontWeight='600'>
          {t('organization')}:
        </Text>
        <HStack w='100%' spacing={{ base: '2', lg: '6' }}>
          <Box w={{ base: '50%', md: '50%' }} as={Link} href='https://acm.pt/' target='_blank'>
            <Image src={acm} />
          </Box>
          <Box
            w={{ base: '50%', md: '50%' }}
            as={Link}
            href='https://fpciclismo.pt/'
            target='_blank'
          >
            <Image src={fpc} />
          </Box>
        </HStack>
      </VStack>
      <VStack w='100%' align='start' spacing='2'>
        <Text fontSize={{ base: 'lg', xl: 'xl' }} fontWeight='600'>
          {t('main_sponsors')}:
        </Text>
        <SimpleGrid columns={[4, 8]} gap={['3', '6']}>
          {principais.map(parceiro => (
            <Box
              key={parceiro.link}
              as={Link}
              justifySelf='center'
              alignSelf='center'
              href={parceiro.link}
              target='_blank'
            >
              <Image src={parceiro.imagem} maxH={['50px', '80px']} />
            </Box>
          ))}
        </SimpleGrid>
      </VStack>
      <VStack w='100%' align='start' spacing='2'>
        <Text fontSize={{ base: 'lg', xl: 'xl' }} fontWeight='600'>
          {t('sponsors')}:
        </Text>
        <SimpleGrid columns={[4, 8]} gap={['3', '6']}>
          {outros.map(parceiro => (
            <Box
              key={parceiro.link}
              as={Link}
              justifySelf='center'
              alignSelf='center'
              href={parceiro.link}
              target='_blank'
            >
              <Image src={parceiro.imagem} maxH={['50px', '80px']} />
            </Box>
          ))}
        </SimpleGrid>
      </VStack>
    </VStack>
  );
}
