import { useTranslation } from 'react-i18next';

import { SimpleGrid, Text, VStack } from '@chakra-ui/react';

import useCountdown from '../hooks/useCountdown';

export default function Countdown({ endDate, shadow = true }: { endDate: Date; shadow?: boolean }) {
  const { timeLeft, leadingZero } = useCountdown(endDate);
  const { t } = useTranslation();

  if (endDate.getTime() < Date.now()) return null;

  return (
    <SimpleGrid
      w={{ base: '100%', md: '75%', lg: '50%' }}
      columns={timeLeft.days !== 0 ? 4 : 3}
      gap='4'
      zIndex='2'
      fontFamily='NeoSans'
      textShadow={shadow ? '0px 8px 8px rgba(0, 0, 0, 0.5)' : 'none'}
    >
      {timeLeft.days !== 0 && (
        <VStack spacing='0' lineHeight='300%'>
          <Text color='white' fontSize={{ base: '35', md: '50', lg: '70' }}>
            {timeLeft.days}
          </Text>
          <Text color='white'>{t('day', { count: timeLeft.days })}</Text>
        </VStack>
      )}
      <VStack spacing='0' lineHeight='300%'>
        <Text color='white' fontSize={{ base: '35', md: '50', lg: '70' }}>
          {leadingZero(timeLeft.hours || 0)}
        </Text>
        <Text color='white'>{t('hour', { count: timeLeft.hours })}</Text>
      </VStack>
      <VStack spacing='0' lineHeight='300%'>
        <Text color='white' fontSize={{ base: '35', md: '50', lg: '70' }}>
          {leadingZero(timeLeft.minutes || 0)}
        </Text>
        <Text color='white'>{t('minute', { count: timeLeft.minutes })}</Text>
      </VStack>
      <VStack spacing='0' lineHeight='300%'>
        <Text color='white' fontSize={{ base: '35', md: '50', lg: '70' }}>
          {leadingZero(timeLeft.seconds || 0)}
        </Text>
        <Text color='white'>{t('second', { count: timeLeft.seconds })}</Text>
      </VStack>
    </SimpleGrid>
  );
}
