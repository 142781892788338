import type { LangType } from '.';

const en: LangType = {
  translation: {
    name_ordinal_one: "{{count}}st Minho's Great Prix",
    name_ordinal_two: "{{count}}nd Minho's Great Prix",
    name_ordinal_few: "{{count}}rd Minho's Great Prix",
    name_ordinal_other: "{{count}}th Minho's Great Prix",
    name: "Minho's Great Prix",
    prizes: {
      prizes: 'Prizes',
      yellow: 'Individual General',
      green: 'Points General',
      red: 'Intermediate Sprints General',
      dots: 'Mountain General',
      orange: 'Best 1st Year Junior',
      white: 'Best ACM Athlete',
    },
    day_one: 'day',
    day_other: 'days',
    hour_one: 'hour',
    hour_other: 'hours',
    minute_one: 'minute',
    minute_other: 'minutes',
    second_one: 'second',
    second_other: 'seconds',
    dates: '{{begin}} to {{end}} of July',
    powered_by: 'powered by',
    teams: 'Teams',
    organization: 'Organization',
    main_sponsors: 'Main sponsors',
    sponsors: 'Sponsors',
    stage: {
      stage_one: 'Stage',
      stage_other: 'Stages',
      see: 'See stage',
      map: 'Stage Map',
      profile: 'Stage Profile',
      final: 'Final 3km',
      next: 'Next stage',
    },
    date: '{{day}} July',
    results: 'Results',
    news: 'News',
    book: 'Event Book',
    rules: 'Regulation',
  },
};

export default en;
