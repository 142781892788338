import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';

import Layout from './components/Layout';
import Home from './pages/Home';
import Stage1 from './pages/Stages/stage-1';
import Stage2 from './pages/Stages/stage-2';
import Stage3 from './pages/Stages/stage-3';
import Premios from './pages/Prizes';
import theme from './theme';
import { RefreshContextProvider } from './contexts/RefreshContext';

export const App = () => (
  <ChakraProvider theme={theme}>
    <RefreshContextProvider>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path='/'>
              <Route index element={<Home />} />
              <Route path='/stage/1' element={<Stage1 />} />
              <Route path='/stage/2' element={<Stage2 />} />
              <Route path='/stage/3' element={<Stage3 />} />
              <Route path='prizes' element={<Premios />} />
            </Route>
          </Routes>
        </Layout>
      </BrowserRouter>
      s
    </RefreshContextProvider>
  </ChakraProvider>
);
