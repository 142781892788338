import React from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Container,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  PopoverTrigger as OrigPopoverTrigger,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';

import logo from '../../assets/logo.png';
import acm from '../../assets/organizers/acm.png';
import fpc from '../../assets/organizers/fpc.png';
import LanguageSelector from '../../localization/components/Selector';

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> = OrigPopoverTrigger;

export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <Flex
      h='100px'
      w='100%'
      justify='center'
      position='fixed'
      zIndex='999'
      px={{ base: '2', md: '8', lg: '20' }}
      mt='4'
    >
      <Container w='100%' maxW='1600px'>
        <HStack
          h='100%'
          w='100%'
          alignItems='center'
          justifyContent='space-between'
          px={{ base: '2', md: '4', lg: '8' }}
          py='4'
          bgColor='rgba(255, 255, 255, 0.3)'
          borderRadius='xl'
          backdropFilter='blur(10px)'
        >
          <HStack h='100%' w={['50%', '15%']} zIndex='3'>
            <Box as={RouterLink} to='/'>
              <Image src={logo} />
            </Box>
            <VStack h='100%' justify='space-between'>
              <Box as={Link} href='https://acm.pt' target='_blank'>
                <Image src={acm} />
              </Box>
              <Box as={Link} href='https://fpciclismo.pt' target='_blank'>
                <Image src={fpc} />
              </Box>
            </VStack>
          </HStack>

          {/* <Countdown endDate={new Date('2024-07-19')} t={t} /> */}

          <Box display={{ base: 'block', md: 'none' }} cursor='pointer' zIndex='3' onClick={onOpen}>
            <Icon boxSize='6' as={isOpen ? FiX : FiMenu} />
          </Box>

          <Drawer isOpen={isOpen} onClose={onClose} size='full' closeOnEsc>
            <DrawerOverlay zIndex='2' bg='white' />
            <DrawerContent mt='100px' bg='none' border='0' overflowY='auto' h='100%'>
              <VStack
                w='100%'
                divider={<Divider bg='whiteAlpha.400' />}
                px='8'
                pt='6'
                fontFamily='Monument Extended'
                spacing='5'
                fontSize='lg'
              >
                <NavLink
                  to={null}
                  href='https://www.acm.pt/index.php?cat=resultados&id=1613&subcat=noticias'
                  label={t('news')}
                />
                {/* <Classificacoes t={t} /> */}
                <NavLink
                  to={null}
                  href='http://acm.pt/acmcsv/prova_1555_2.pdf'
                  label={t('rules')}
                />
                <NavLink to={null} href='http://acm.pt/acmcsv/prova_1555_1.pdf' label={t('book')} />
              </VStack>
            </DrawerContent>
          </Drawer>

          <HStack spacing='8' display={{ base: 'none', md: 'flex' }}>
            <NavLink
              to={null}
              href='https://www.acm.pt/index.php?cat=resultados&id=1650&subcat=noticias'
              label={t('news')}
            />
            {/* <Classificacoes t={t} /> */}
            <NavLink
              to={null}
              href='https://acm.pt/acmcsv/regulamento_1650.pdf'
              label={t('rules')}
            />
            {/* <NavLink to={null} href='http://acm.pt/acmcsv/prova_1613_1.pdf' label={t('book')} /> */}
            <LanguageSelector />
          </HStack>
        </HStack>
      </Container>
    </Flex>
  );
}

// function Countdown({ endDate, t }: { endDate: Date; t: TFunction<'translation', undefined> }) {
//   const { timeLeft } = useCountdown(endDate);

//   if (endDate.getTime() < Date.now()) return null;

//   return (
//     <HStack spacing='3' zIndex='2' display={{ base: 'none', md: 'flex' }}>
//       {timeLeft.days !== 0 && (
//         <HStack spacing='1'>
//           <Text color='black' fontSize={['xs', 'lg']}>
//             {timeLeft.days}
//           </Text>
//           <Text color='black' fontSize={['xs', 'lg']}>
//             {t('day', { count: timeLeft.days })}
//           </Text>
//         </HStack>
//       )}
//       <HStack spacing='1'>
//         <Text color='black' fontSize={['xs', 'lg']}>
//           {timeLeft.hours || 0}
//         </Text>
//         <Text color='black' fontSize={['xs', 'lg']}>
//           {t('hour', { count: timeLeft.days })}
//         </Text>
//       </HStack>
//       <HStack spacing='1'>
//         <Text color='black' fontSize={['xs', 'lg']}>
//           {timeLeft.minutes || 0}
//         </Text>
//         <Text color='black' fontSize={['xs', 'lg']}>
//           {t('minute', { count: timeLeft.days })}
//         </Text>
//       </HStack>
//       <HStack spacing='1'>
//         <Text color='black' fontSize={['xs', 'lg']}>
//           {timeLeft.seconds || 0}
//         </Text>
//         <Text color='black' fontSize={['xs', 'lg']}>
//           {t('second', { count: timeLeft.days })}
//         </Text>
//       </HStack>
//     </HStack>
//   );
// }

function NavLink({ label, to, href, ...rest }) {
  const location = useLocation();
  const isActive = to ? location.pathname.includes(to) : false;

  if (href) {
    return (
      <Box transition='all 0.1s' {...rest}>
        <Link
          as={Link}
          href={href}
          target='_blank'
          color={isActive ? 'brand.500' : 'gray.800'}
          fontWeight={isActive ? '600' : 'normal'}
          _hover={{ color: 'brand.500' }}
        >
          {label}
        </Link>
      </Box>
    );
  }

  return (
    <Box transition='all 0.1s' {...rest}>
      <Text
        as={RouterLink}
        to={to}
        color={isActive ? 'brand.500' : 'gray.800'}
        fontWeight={isActive ? '600' : 'normal'}
        _hover={{ color: 'brand.500' }}
      >
        {label}
      </Text>
    </Box>
  );
}

// function Classificacoes({ t }: { t: TFunction<'translation', undefined> }) {
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const location = useLocation();
//   const isActive = location.pathname.includes('classificacoes');

//   return (
//     <Popover
//       isOpen={isOpen}
//       onOpen={onOpen}
//       onClose={onClose}
//       trigger='hover'
//       isLazy
//       placement='bottom'
//     >
//       <PopoverTrigger>
//         <Text
//           cursor='pointer'
//           color={isActive ? 'brand.500' : 'gray.900'}
//           fontWeight={isActive ? '600' : 'normal'}
//           _hover={{ color: 'brand.500' }}
//         >
//           {t('results')}
//         </Text>
//       </PopoverTrigger>
//       <PopoverContent border='0' py='2' boxShadow='lg'>
//         <PopoverArrow />
//         <PopoverBody p='0'>
//           <VStack w='100%' align='start' spacing='2'>
//             <Box
//               as={Link}
//               href='http://acm.pt/acmcsv/prova_1613_5.pdf'
//               target='_blank'
//               w='100%'
//               px='4'
//               py='2'
//               _hover={{ bg: 'brand.500', color: 'white' }}
//             >
//               <Text>Paredes de Coura</Text>
//             </Box>
//             <Box
//               as={Link}
//               href='https://www.acm.pt/acmcsv/prova_1613_6.pdf'
//               target='_blank'
//               w='100%'
//               px='4'
//               py='2'
//               _hover={{ bg: 'brand.500', color: 'white' }}
//             >
//               <Text>Azúrem / Guimarães</Text>
//             </Box>
//             <Box
//               as={Link}
//               href='http://acm.pt/acmcsv/prova_1613_5.pdf'
//               target='_blank'
//               w='100%'
//               px='4'
//               py='2'
//               _hover={{ bg: 'brand.500', color: 'white' }}
//             >
//               <Text>Oliveira Santa Maria / Famalicão</Text>
//             </Box>
//           </VStack>
//         </PopoverBody>
//       </PopoverContent>
//     </Popover>
//   );
// }
