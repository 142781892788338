import React from 'react';

import { Box, Heading, Stack, Image, SimpleGrid, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const jerseys = [
  {
    category: 'prizes.yellow',
    src: require('../../assets/camisolas/2024/amarela.jpg'),
    link: 'https://www.cm-viana-castelo.pt/ced2023/',
  },
  {
    category: 'prizes.green',
    src: require('../../assets/camisolas/2024/verde.jpg'),
    link: 'https://autoterror.com/',
  },
  {
    category: 'prizes.dots',
    src: require('../../assets/camisolas/2024/montanha.jpg'),
    link: 'https://www.pavimogege.com/',
  },
  {
    category: 'prizes.red',
    src: require('../../assets/camisolas/2024/vermelha.jpg'),
    link: 'http://www.i9tex.pt/',
  },
  {
    category: 'prizes.orange',
    src: require('../../assets/camisolas/2024/laranja.jpg'),
    link: 'https://www.facebook.com/arrecadacoes',
  },
  {
    category: 'prizes.white',
    src: require('../../assets/camisolas/2024/branca.jpg'),
    link: 'https://acm.pt/',
  },
];

export default function Premios({ ...props }) {
  const { t } = useTranslation();

  return (
    <SimpleGrid columns={{ base: 2, lg: 3 }} gap='6' {...props}>
      {jerseys.map((jersey, index) => (
        <Stack maxH='350px' direction='column' align='center' p='2' key={index}>
          <Box h='90%' w='100%'>
            <Image src={jersey.src} w='100%' h='100%' objectFit='contain' />
          </Box>
          <VStack h='10%' w='100%' textTransform='uppercase'>
            <Heading w='100%' textAlign='center' fontSize={['xs', 'md']}>
              {t(jersey.category)}
            </Heading>
          </VStack>
        </Stack>
      ))}
    </SimpleGrid>
  );
}
