// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translation: {
    name_ordinal_one: '{{count}}º Grande Prémio do Minho',
    name_ordinal_two: '{{count}}º Grande Prémio do Minho',
    name_ordinal_few: '{{count}}º Grande Prémio do Minho',
    name_ordinal_other: '{{count}}º Grande Prémio do Minho',
    name: 'Grande Prémio do Minho',
    prizes: {
      prizes: 'Prémios',
      yellow: 'Geral Individual',
      green: 'Geral Pontos',
      red: 'Geral Metas Volantes',
      dots: 'Geral Montanha',
      orange: 'Melhor Júnior 1º Ano',
      white: 'Melhor Atleta ACM',
    },
    day_one: 'dia',
    day_other: 'dias',
    hour_one: 'hora',
    hour_other: 'horas',
    minute_one: 'minuto',
    minute_other: 'minutos',
    second_one: 'segundo',
    second_other: 'segundos',
    dates: '{{begin}} a {{end}} de julho',
    powered_by: 'com o apoio de',
    teams: 'Equipas',
    organization: 'Organização',
    main_sponsors: 'Apoios principais',
    sponsors: 'Apoios',
    stage: {
      stage_one: 'Etapa',
      stage_other: 'Etapas',
      see: 'Ver etapa',
      map: 'Mapa da Etapa',
      profile: 'Perfil da Etapa',
      final: 'Últimos 3km',
      next: 'Próxima etapa',
    },
    date: '{{day}} julho',
    results: 'Classificações',
    news: 'Notícias',
    book: 'Livro de Prova',
    rules: 'Regulamento',
  },
};
