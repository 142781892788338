import React from 'react';

import adrap from '../../assets/teams/2024/adrap.png';
import alcobaca from '../../assets/teams/2024/alcobaca.png';
import bairrada from '../../assets/teams/2024/bairrada.png';
import brunoneves from '../../assets/teams/2024/bruno-neves.png';
import callejo from '../../assets/teams/2024/callejo.png';
import camargo from '../../assets/teams/2024/camargo.png';
import cambre from '../../assets/teams/2024/cambre-caeiro.png';
import ccbarcelos from '../../assets/teams/2024/ccbarcelos.png';
import cortizo from '../../assets/teams/2024/cortizo.png';
import dunas from '../../assets/teams/2024/dunas-vale.png';
import efapel from '../../assets/teams/2024/efapel.png';
import electromercantil from '../../assets/teams/2024/eletromercantil.png';
import farto from '../../assets/teams/2024/farto.png';
import hegotarrak from '../../assets/teams/2024/hegotarrak.png';
import lasal from '../../assets/teams/2024/lasal.png';
import landeiro from '../../assets/teams/2024/landeiro.png';
import loule from '../../assets/teams/2024/loule.png';
import matos from '../../assets/teams/2024/matos-cheirinhos.png';
import paio from '../../assets/teams/2024/paio-pires.png';
import paredes from '../../assets/teams/2024/paredes.png';
import picusa from '../../assets/teams/2024/picusa.png';
// import porminho from '../../assets/teams/2024/porminho.png';
import santamaria from '../../assets/teams/2024/santa-maria-feira.png';
import serman from '../../assets/teams/2024/serman.png';
import talavera from '../../assets/teams/2024/talavera.png';
import tensai from '../../assets/teams/2024/tensai.png';

import { Box, Heading, Image, SimpleGrid, VStack } from '@chakra-ui/react';

const jerseys = [
  {
    name: 'Alcobaça CC / Crédito Agricola',
    src: alcobaca,
  },
  {
    name: 'ABTF Betão - BAIRRADA',
    src: bairrada,
  },
  {
    name: 'Escola de Ciclismo Bruno Neves',
    src: brunoneves,
  },
  {
    name: 'Recambios Callejo',
    src: callejo,
  },
  {
    name: 'CAMARGO-BEKO',
    src: camargo,
  },
  {
    name: 'Cambre - Servicios Reunidos',
    src: cambre,
  },
  {
    name: 'Centro Ciclismo de Loulé / Matdiver / Golfejardim / Loulé Doce',
    src: loule,
  },
  {
    name: "CC Barcelos / AFF / Flynx / H.M.Motor / Segmento D'Época",
    src: ccbarcelos,
  },
  {
    name: 'Padrones - Cortizo',
    src: cortizo,
  },
  {
    name: 'DunasVale / Pereira&Gago',
    src: dunas,
  },
  {
    name: 'Academia Efapel de Ciclismo',
    src: efapel,
  },
  {
    name: 'Electromercantil GR-100',
    src: electromercantil,
  },
  {
    name: 'FARTO - BTC',
    src: farto,
  },
  {
    name: 'ROWUER VOLVO ARREGUI',
    src: hegotarrak,
  },
  {
    name: 'LANDEIRO | KTM | MATIAS&ARAÚJO',
    src: landeiro,
  },
  {
    name: 'STM VOLTIA-CRAEGA',
    src: lasal,
  },
  {
    name: 'TEAM GRUPO SERMAN',
    src: serman,
  },
  {
    name: 'LA Aluminios / Vila Galé / Matos Cheirinhos',
    src: matos,
  },
  // {
  //   name: 'Milharado',
  //   src: milharado,
  // },
  {
    name: 'MUNDIMAT / SGR AMBIENTE / CCA PAIO PIRES',
    src: paio,
  },
  {
    name: 'Paredes / Fortunna',
    src: paredes,
  },
  {
    name: 'Picusa Academy',
    src: picusa,
  },
  // {
  //   name: 'Porminho',
  //   src: porminho,
  // },
  {
    name: "Stª Maria Feira / Segmento D'Época / Reol / Bolf",
    src: santamaria,
  },
  {
    name: 'Silva&Vinha / ADRAP / Sentir Penafiel',
    src: adrap,
  },
  {
    name: 'Team Junior Ciudad de Talavera',
    src: talavera,
  },
  {
    name: 'Tensai / Sambiental / Santa Marta',
    src: tensai,
  },
];

export default function Equipas({ ...props }) {
  return (
    <SimpleGrid columns={{ base: 2, md: 3, lg: 5 }} gap={{ base: 4, lg: 8 }} {...props}>
      {jerseys
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((jersey, index) => (
          <VStack align='center' spacing={{ base: '1', md: '2' }} key={index}>
            <Box w='100%' h={{ base: '220px', lg: '300px' }} maxW='220px'>
              <Image src={jersey.src} w='100%' h='100%' objectFit='fill' />
            </Box>
            <Box w='100%'>
              <Heading textAlign='center' fontSize='sm' fontWeight='600'>
                {jersey.name}
              </Heading>
            </Box>
          </VStack>
        ))}
    </SimpleGrid>
  );
}
